import FreeIpApiService from '@/services/ip'
export default {
    methods: {
        async getVisitorCountryCode () {
            console.log("getting visitor country code")
            if ( !localStorage.getItem('userCountryCode') ) {
                console.log('no country code in local storage')
                await this.setVisitorCountryCode()
            }
            return localStorage.getItem('userCountryCode')
        },
      
        async setVisitorCountryCode () {
            console.log("setting visitor country code")
            const apiService = new FreeIpApiService();
            const visitorCountryCode = await apiService.getCurrentVisitorCountryCode()
            console.log("visitorCountryCode", visitorCountryCode)

            if ( !visitorCountryCode ) {
                throw new Error('Failed to get country')
            }
            localStorage.setItem('userCountryCode', visitorCountryCode)
        }
    }
}