class FreeIpApiService {
    constructor () {
        this.apiUrl = 'https://freeipapi.com/api/json/'
    }

    async getCurrentVisitorData () {
        let response
        try {
            console.log('fetching data from freeipapi.com')
            response = await fetch(this.apiUrl)
            console.log('response', response)
        } catch (e) {
            console.error('Error fetching data from freeipapi.com', e)
            return
        }

        if ( !response || !response.ok) {
            console.error('Unable to get data from freeipapi.com.')
            return
        }
        return await response.json()
    }

    async getCurrentVisitorCountryCode () {
        const parsedResponse = await this.getCurrentVisitorData()
        if ( !parsedResponse || !parsedResponse.countryCode) {
            return
        }
        return parsedResponse.countryCode
        
    }
}

export default FreeIpApiService;